const ADMapPinLight = () => {
	return (
		<>
			<path
				d="M32 57.2408C31.8908 57.1146 31.7591 56.9616 31.6071 56.7832C31.1177 56.2089 30.4178 55.3722 29.5774 54.3253C27.8963 52.2309 25.6556 49.2977 23.4159 45.9412C21.1753 42.5834 18.9416 38.8109 17.2696 35.0373C15.5948 31.2573 14.5 27.5124 14.5 24.2C14.5 14.4089 22.3299 6.5 32 6.5C41.6701 6.5 49.5 14.4089 49.5 24.2C49.5 27.5124 48.4052 31.2573 46.7304 35.0373C45.0584 38.8109 42.8247 42.5834 40.5841 45.9412C38.3444 49.2977 36.1037 52.2309 34.4226 54.3253C33.5822 55.3722 32.8823 56.2089 32.3929 56.7832C32.2409 56.9616 32.1092 57.1146 32 57.2408Z"
				fill="#E5EDF8"
				stroke="#004BB9"
			/>
			<path
				d="M34.7702 20.1308L32.7015 14.8299C32.3337 13.8877 31 13.8877 30.6323 14.8299L29.1133 18.7215H29.4516C31.4209 18.7215 33.2428 19.2353 34.7702 20.1308Z"
				fill="#004BB9"
			/>
			<path
				d="M36.7175 25.1228C36.136 23.6479 35.1241 22.382 33.8134 21.49C32.5027 20.5979 30.9539 20.1209 29.3685 20.1211H28.5667L23.9248 32.0146C23.8804 32.1283 23.8645 32.2512 23.8784 32.3726C23.8923 32.4939 23.9357 32.61 24.0047 32.7107C24.0736 32.8115 24.1662 32.8939 24.2743 32.9508C24.3824 33.0076 24.5027 33.0372 24.6248 33.037H26.8181C26.9515 33.037 27.0819 32.997 27.1923 32.9221C27.3027 32.8473 27.3881 32.741 27.4374 32.617L28.4174 30.1502C28.4482 30.0727 28.5017 30.0062 28.5707 29.9593C28.6398 29.9125 28.7213 29.8875 28.8047 29.8875H34.5275C34.6109 29.8875 34.6923 29.9126 34.7613 29.9594C34.8303 30.0062 34.8836 30.0727 34.9143 30.1502L35.8943 32.617C35.9437 32.741 36.0291 32.8473 36.1395 32.9221C36.2499 32.997 36.3802 33.037 36.5136 33.037H38.7069C38.8291 33.0372 38.9494 33.0076 39.0575 32.9508C39.1656 32.8939 39.2581 32.8115 39.3271 32.7107C39.3961 32.61 39.4394 32.4939 39.4533 32.3726C39.4673 32.2512 39.4513 32.1283 39.4069 32.0146L36.7175 25.1228ZM33.2101 27.101H30.1221C30.0677 27.1011 30.0141 27.0878 29.966 27.0623C29.9179 27.0369 29.8768 27 29.8462 26.955C29.8156 26.91 29.7966 26.8581 29.7906 26.804C29.7847 26.7499 29.7922 26.6952 29.8123 26.6446L31.1875 23.1871C31.2251 23.0908 31.2908 23.008 31.3762 22.9496C31.4615 22.8913 31.5625 22.8601 31.6659 22.8601C31.7693 22.8601 31.8702 22.8913 31.9556 22.9496C32.0409 23.008 32.1067 23.0908 32.1442 23.1871L33.5195 26.6446C33.5396 26.6952 33.547 26.7498 33.5411 26.8039C33.5352 26.858 33.5162 26.9098 33.4857 26.9548C33.4552 26.9998 33.4141 27.0367 33.3661 27.0622C33.318 27.0877 33.2645 27.101 33.2101 27.101Z"
				fill="#004BB9"
			/>
			<path
				d="M39.5874 32.8583C39.5477 32.849 39.509 32.8396 39.509 32.7981C39.509 32.7566 39.5412 32.7416 39.5822 32.7416C39.6233 32.7416 39.6541 32.7603 39.6555 32.799H39.6961C39.6938 32.7374 39.6438 32.7057 39.5808 32.7057C39.5178 32.7057 39.4698 32.737 39.4698 32.799C39.4698 32.8611 39.5253 32.8802 39.5729 32.8924C39.6084 32.9008 39.6592 32.9092 39.6592 32.954C39.6592 32.9885 39.6289 33.0081 39.5888 33.0081C39.5779 33.0093 39.5669 33.0081 39.5565 33.0048C39.546 33.0014 39.5365 32.9959 39.5283 32.9886C39.5202 32.9813 39.5137 32.9724 39.5092 32.9624C39.5047 32.9524 39.5024 32.9416 39.5024 32.9306H39.46C39.4603 32.9466 39.464 32.9623 39.4707 32.9768C39.4774 32.9913 39.487 33.0043 39.4988 33.015C39.5107 33.0256 39.5247 33.0337 39.5398 33.0388C39.555 33.0439 39.571 33.0458 39.5869 33.0445C39.6471 33.0445 39.7012 33.0118 39.7012 32.9512C39.7012 32.8905 39.6368 32.8695 39.5874 32.8583Z"
				fill="#004BB9"
			/>
			<path
				d="M40.019 32.7136L39.9206 32.9736L39.8226 32.7136H39.7656V33.0366H39.8034V32.7701L39.9052 33.0366H39.935L40.0368 32.7701V33.0366H40.0755V32.7136H40.019Z"
				fill="#004BB9"
			/>
		</>
	);
};

export default ADMapPinLight;
