const FallBackMapPinDark = () => {
	return (
		<>
			<path
				d="M32 6C22.0486 6 14 14.138 14 24.2C14 37.85 32 58 32 58C32 58 50 37.85 50 24.2C50 14.138 41.9514 6 32 6Z"
				fill="#122C7A"
			/>
			<circle cx="32" cy="24" r="8" fill="white" />
		</>
	);
};

export default FallBackMapPinDark;
