const ChapterMapPinLight = () => {
	return (
		<>
			<path
				d="M32 57.9124C31.8837 57.7796 31.7417 57.6165 31.5767 57.425C31.0691 56.8358 30.3431 55.9776 29.4716 54.9037C27.728 52.7554 25.4041 49.7466 23.0811 46.3037C20.7573 42.8594 18.4405 38.9896 16.7063 35.1185C14.9691 31.2409 13.8333 27.3989 13.8333 24C13.8333 13.9562 21.9561 5.83337 32 5.83337C42.0439 5.83337 50.1667 13.9562 50.1667 24C50.1667 27.3989 49.0309 31.2409 47.2937 35.1185C45.5595 38.9896 43.2427 42.8594 40.9188 46.3037C38.5959 49.7466 36.272 52.7554 34.5284 54.9037C33.6569 55.9776 32.9309 56.8358 32.4233 57.425C32.2583 57.6165 32.1163 57.7796 32 57.9124Z"
				fill="#F7E6E6"
				stroke="#7D373B"
			/>
			<path
				d="M40.7033 30.3897C40.5367 30.2801 40.3722 30.1332 40.2648 29.9622C39.9864 29.5171 39.9294 28.9076 39.9096 28.3968C39.9053 28.285 39.9009 28.1754 39.8987 28.0635C39.8614 26.6911 39.9009 25.3142 39.9009 23.9417C39.9009 21.2494 38.4977 20.3132 36.7218 20.3132C35.4129 20.3132 34.3474 21.1727 33.7335 21.9027V15.8516H33.6962C32.4312 16.1344 31.4687 16.2857 29.8265 16.3997V16.722C30.2606 16.722 30.9403 16.9105 30.9403 17.9673V27.8531C30.9403 29.3264 30.4119 29.6092 29.6183 29.6092V29.9491H35.0555V29.6092C34.2619 29.6092 33.7335 29.3264 33.7335 27.8531V22.8893V22.7951C34.2114 22.1878 35.0095 21.4007 35.8054 21.4007C36.8534 21.4007 37.1318 22.4289 37.1318 23.9439V25.7373C37.1318 27.2655 36.8292 29.0633 38.0548 30.2253C38.4736 30.6243 39.0129 30.8896 39.5786 30.997C39.6948 31.019 41.2536 31.1374 41.2712 31.0168C41.2865 30.9028 41.2755 30.78 41.2755 30.6638C41.2755 30.6506 40.7581 30.427 40.7033 30.3897Z"
				fill="#7D373B"
			/>
			<path
				d="M26.1015 28.6928C23.6109 28.6928 22.5278 26.1824 22.5278 23.808C22.5278 22.3259 23.0606 20.9183 24.238 20.8986C25.4175 20.8986 25.8363 22.7227 25.8538 23.637C26.0621 23.8847 26.4633 24.036 26.8996 24.036C27.5661 24.036 28.2304 23.694 28.2304 22.8959C28.2304 21.5848 26.8053 20.254 24.466 20.254C21.6509 20.254 19.75 22.1373 19.75 25.0073C19.75 27.9539 21.7846 30.0653 24.9023 30.0653C26.8031 30.0653 28.134 28.8879 28.59 28.3354L28.305 28.0504C27.6406 28.4845 26.8996 28.6928 26.1015 28.6928Z"
				fill="#7D373B"
			/>
			<path
				d="M43.6916 29.2607C44.3624 29.2607 44.9062 28.7169 44.9062 28.046C44.9062 27.3752 44.3624 26.8314 43.6916 26.8314C43.0208 26.8314 42.477 27.3752 42.477 28.046C42.477 28.7169 43.0208 29.2607 43.6916 29.2607Z"
				fill="#7D373B"
			/>
		</>
	);
};

export default ChapterMapPinLight;
