const ClearChoiceMapPinLight = () => {
	return (
		<>
			<path
				d="M32 57.9124C31.8837 57.7796 31.7418 57.6165 31.5768 57.425C31.0692 56.8358 30.3432 55.9776 29.4716 54.9037C27.728 52.7554 25.4041 49.7466 23.0812 46.3037C20.7573 42.8593 18.4406 38.9895 16.7063 35.1185C14.9692 31.2409 13.8334 27.3988 13.8334 24C13.8334 13.9561 21.9562 5.83333 32 5.83333C42.0439 5.83333 50.1667 13.9561 50.1667 24C50.1667 27.3988 49.0309 31.2409 47.2937 35.1185C45.5595 38.9895 43.2428 42.8593 40.9189 46.3037C38.596 49.7466 36.272 52.7554 34.5285 54.9037C33.6569 55.9776 32.9309 56.8358 32.4233 57.425C32.2583 57.6165 32.1164 57.7796 32 57.9124Z"
				fill="#B6C1CC"
				stroke="#0A2F54"
			/>
			<path
				d="M34.8288 21.5124L46.5 20.6564L36.5892 26.9667L40.9978 37.962L32.0228 30.3492L23.0722 38L27.4332 26.9853L17.5 20.7175L29.172 21.5242L31.9772 10L34.8288 21.5124Z"
				fill="#0A2F54"
			/>
		</>
	);
};

export default ClearChoiceMapPinLight;
