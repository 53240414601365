const FallBackMapPinLight = () => {
	return (
		<>
			<path
				d="M32 57.2408C31.8908 57.1146 31.7591 56.9616 31.6071 56.7832C31.1177 56.2089 30.4178 55.3722 29.5774 54.3253C27.8963 52.2309 25.6556 49.2977 23.4159 45.9412C21.1753 42.5834 18.9416 38.8109 17.2696 35.0373C15.5948 31.2573 14.5 27.5124 14.5 24.2C14.5 14.4089 22.3299 6.5 32 6.5C41.6701 6.5 49.5 14.4089 49.5 24.2C49.5 27.5124 48.4052 31.2573 46.7304 35.0373C45.0584 38.8109 42.8248 42.5834 40.5841 45.9412C38.3444 49.2977 36.1037 52.2309 34.4226 54.3253C33.5822 55.3722 32.8823 56.2089 32.3929 56.7832C32.2409 56.9616 32.1092 57.1146 32 57.2408Z"
				fill="#F2F3F4"
				stroke="#122C7A"
			/>
			<circle cx="32" cy="24" r="8" fill="#122C7A" />
		</>
	);
};

export default FallBackMapPinLight;
