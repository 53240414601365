const ADMapPinDark = () => {
	return (
		<>
			<path
				d="M32 6C22.0486 6 14 14.138 14 24.2C14 37.85 32 58 32 58C32 58 50 37.85 50 24.2C50 14.138 41.9514 6 32 6Z"
				fill="#002855"
			/>
			<path
				d="M35.1037 20.1308L33.035 14.8299C32.6672 13.8877 31.3335 13.8877 30.9658 14.8299L29.4468 18.7215H29.7851C31.7544 18.7215 33.5763 19.2353 35.1037 20.1308Z"
				fill="white"
			/>
			<path
				d="M37.051 25.1228C36.4695 23.6479 35.4576 22.382 34.1469 21.49C32.8362 20.5979 31.2874 20.1209 29.702 20.1211H28.9002L24.2583 32.0146C24.2139 32.1283 24.198 32.2512 24.2119 32.3726C24.2258 32.4939 24.2692 32.61 24.3382 32.7107C24.4071 32.8115 24.4997 32.8939 24.6078 32.9508C24.7159 33.0076 24.8362 33.0372 24.9583 33.037H27.1516C27.285 33.037 27.4154 32.997 27.5258 32.9221C27.6362 32.8473 27.7216 32.741 27.7709 32.617L28.7509 30.1502C28.7817 30.0727 28.8351 30.0062 28.9042 29.9593C28.9733 29.9125 29.0548 29.8875 29.1382 29.8875H34.861C34.9444 29.8875 35.0258 29.9126 35.0948 29.9594C35.1638 30.0062 35.2171 30.0727 35.2478 30.1502L36.2278 32.617C36.2772 32.741 36.3626 32.8473 36.473 32.9221C36.5834 32.997 36.7137 33.037 36.8471 33.037H39.0404C39.1626 33.0372 39.2829 33.0076 39.391 32.9508C39.499 32.8939 39.5916 32.8115 39.6606 32.7107C39.7296 32.61 39.7729 32.4939 39.7868 32.3726C39.8007 32.2512 39.7848 32.1283 39.7404 32.0146L37.051 25.1228ZM33.5436 27.101H30.4556C30.4012 27.1011 30.3476 27.0878 30.2995 27.0623C30.2514 27.0369 30.2103 27 30.1797 26.955C30.1491 26.91 30.13 26.8581 30.1241 26.804C30.1182 26.7499 30.1256 26.6952 30.1458 26.6446L31.521 23.1871C31.5586 23.0908 31.6243 23.008 31.7097 22.9496C31.795 22.8913 31.896 22.8601 31.9994 22.8601C32.1028 22.8601 32.2037 22.8913 32.2891 22.9496C32.3744 23.008 32.4402 23.0908 32.4777 23.1871L33.853 26.6446C33.8731 26.6952 33.8805 26.7498 33.8746 26.8039C33.8687 26.858 33.8497 26.9098 33.8192 26.9548C33.7887 26.9998 33.7476 27.0367 33.6996 27.0622C33.6515 27.0877 33.598 27.101 33.5436 27.101Z"
				fill="white"
			/>
			<path
				d="M39.9209 32.8583C39.8812 32.849 39.8425 32.8396 39.8425 32.7981C39.8425 32.7566 39.8747 32.7416 39.9157 32.7416C39.9568 32.7416 39.9876 32.7603 39.989 32.799H40.0296C40.0273 32.7374 39.9773 32.7057 39.9143 32.7057C39.8513 32.7057 39.8033 32.737 39.8033 32.799C39.8033 32.8611 39.8588 32.8802 39.9064 32.8924C39.9419 32.9008 39.9927 32.9092 39.9927 32.954C39.9927 32.9885 39.9624 33.0081 39.9223 33.0081C39.9114 33.0093 39.9004 33.0081 39.89 33.0048C39.8795 33.0014 39.87 32.9959 39.8618 32.9886C39.8537 32.9813 39.8472 32.9724 39.8427 32.9624C39.8382 32.9524 39.8359 32.9416 39.8359 32.9306H39.7935C39.7938 32.9466 39.7975 32.9623 39.8042 32.9768C39.8109 32.9913 39.8205 33.0043 39.8323 33.015C39.8442 33.0256 39.8582 33.0337 39.8733 33.0388C39.8885 33.0439 39.9045 33.0458 39.9204 33.0445C39.9806 33.0445 40.0347 33.0118 40.0347 32.9512C40.0347 32.8905 39.9703 32.8695 39.9209 32.8583Z"
				fill="white"
			/>
			<path
				d="M40.3525 32.7136L40.2541 32.9736L40.1561 32.7136H40.0991V33.0366H40.1369V32.7701L40.2387 33.0366H40.2685L40.3703 32.7701V33.0366H40.409V32.7136H40.3525Z"
				fill="white"
			/>
		</>
	);
};

export default ADMapPinDark;
