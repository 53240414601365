const WellNowMapPinDark = () => {
	return (
		<>
			<path
				d="M32 6C22.0486 6 14 14.138 14 24.2C14 37.85 32 58 32 58C32 58 50 37.85 50 24.2C50 14.138 41.9514 6 32 6Z"
				fill="#003592"
			/>
			<path
				d="M32.0153 25.0167C31.7666 25.017 31.5282 25.1159 31.3524 25.2917C31.1766 25.4676 31.0778 25.706 31.0776 25.9547V30.0314C31.0821 30.2772 31.1829 30.5114 31.3583 30.6836C31.5337 30.8558 31.7697 30.9523 32.0155 30.9523C32.2613 30.9523 32.4973 30.8558 32.6726 30.6836C32.848 30.5114 32.9488 30.2772 32.9533 30.0314V25.9547C32.953 25.706 32.8541 25.4676 32.6783 25.2917C32.5024 25.1159 32.264 25.017 32.0153 25.0167Z"
				fill="white"
			/>
			<path
				d="M32.0153 17.0345C31.7666 17.0348 31.5282 17.1337 31.3524 17.3096C31.1766 17.4854 31.0778 17.7239 31.0776 17.9725V22.0493C31.0821 22.295 31.1829 22.5292 31.3583 22.7014C31.5337 22.8737 31.7697 22.9702 32.0155 22.9702C32.2613 22.9702 32.4973 22.8737 32.6726 22.7014C32.848 22.5292 32.9488 22.295 32.9533 22.0493V17.9725C32.953 17.7238 32.8541 17.4854 32.6783 17.3095C32.5024 17.1337 32.264 17.0348 32.0153 17.0345Z"
				fill="white"
			/>
			<path
				d="M30.0626 23.0657H25.9858C25.8612 23.0634 25.7374 23.086 25.6217 23.1321C25.5059 23.1782 25.4005 23.247 25.3116 23.3343C25.2226 23.4216 25.152 23.5257 25.1038 23.6406C25.0556 23.7556 25.0308 23.8789 25.0308 24.0035C25.0308 24.1281 25.0556 24.2515 25.1038 24.3664C25.152 24.4813 25.2226 24.5855 25.3116 24.6728C25.4005 24.7601 25.5059 24.8288 25.6217 24.8749C25.7374 24.9211 25.8612 24.9436 25.9858 24.9414H30.0626C30.3083 24.9369 30.5425 24.8361 30.7147 24.6607C30.887 24.4853 30.9835 24.2493 30.9835 24.0035C30.9835 23.7577 30.887 23.5217 30.7147 23.3464C30.5425 23.171 30.3083 23.0702 30.0626 23.0657Z"
				fill="white"
			/>
			<path
				d="M38.045 23.0657H33.9683C33.8437 23.0634 33.7199 23.086 33.6041 23.1321C33.4883 23.1782 33.3829 23.247 33.294 23.3343C33.2051 23.4216 33.1344 23.5257 33.0862 23.6406C33.038 23.7556 33.0132 23.8789 33.0132 24.0035C33.0132 24.1281 33.038 24.2515 33.0862 24.3664C33.1344 24.4813 33.2051 24.5855 33.294 24.6728C33.3829 24.7601 33.4883 24.8288 33.6041 24.8749C33.7199 24.9211 33.8437 24.9436 33.9683 24.9414H38.045C38.2908 24.9369 38.5249 24.8361 38.6972 24.6607C38.8694 24.4853 38.9659 24.2493 38.9659 24.0035C38.9659 23.7577 38.8694 23.5217 38.6972 23.3464C38.5249 23.171 38.2908 23.0702 38.045 23.0657Z"
				fill="white"
			/>
			<path
				d="M32 32.572C27.2733 32.572 23.428 28.7267 23.428 24C23.428 19.2733 27.2733 15.428 32 15.428C36.7267 15.428 40.5717 19.2733 40.5717 24C40.5717 28.7267 36.7267 32.572 32 32.572ZM32 12.7891C25.8184 12.7891 20.7891 17.8181 20.7891 24C20.7891 30.1819 25.8184 35.2109 32 35.2109C38.1816 35.2109 43.2109 30.1816 43.2109 24C43.2109 17.8184 38.1816 12.7891 32 12.7891Z"
				fill="white"
			/>
		</>
	);
};

export default WellNowMapPinDark;
