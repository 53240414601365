const AzPetVetMapPinLight = () => {
	return (
		<>
			<path
				d="M32 57.9124C31.8837 57.7796 31.7418 57.6165 31.5768 57.425C31.0692 56.8358 30.3432 55.9776 29.4716 54.9037C27.728 52.7554 25.4041 49.7466 23.0812 46.3037C20.7573 42.8594 18.4406 38.9896 16.7063 35.1185C14.9692 31.2409 13.8334 27.3989 13.8334 24C13.8334 13.9562 21.9562 5.83337 32 5.83337C42.0439 5.83337 50.1667 13.9562 50.1667 24C50.1667 27.3989 49.0309 31.2409 47.2937 35.1185C45.5595 38.9896 43.2428 42.8594 40.9189 46.3037C38.596 49.7466 36.272 52.7554 34.5285 54.9037C33.6569 55.9776 32.9309 56.8358 32.4233 57.425C32.2583 57.6165 32.1164 57.7796 32 57.9124Z"
				fill="#FBEBE9"
				stroke="#661D12"
			/>
			<path
				d="M36.9741 26.5817C36.9273 26.5575 36.8761 26.5334 36.825 26.5092C36.3495 26.296 35.9424 25.9266 35.6525 25.4474C34.7914 24.023 33.2161 23.069 31.9179 23.069C30.6198 23.069 29.0957 24.0098 28.2537 25.4277C27.9617 25.9201 27.5481 26.2981 27.0642 26.5202C27.0195 26.5399 26.9768 26.5598 26.9363 26.5817C25.2119 27.4522 24.4338 29.7494 25.2012 31.708C25.9686 33.6688 27.9893 34.5503 29.7139 33.6798C29.7544 33.66 29.797 33.6358 29.8417 33.6117C31.1676 32.8775 32.7428 32.8775 34.0688 33.6117C34.1135 33.6358 34.1561 33.6578 34.1966 33.6798C35.9211 34.5503 37.9419 33.6688 38.7093 31.708C39.4766 29.7472 38.6986 27.4522 36.9741 26.5817Z"
				fill="#5F1307"
			/>
			<path
				d="M26.1178 22.6491C27.1303 24.7901 27.2625 25.6387 25.7533 26.397C24.2441 27.1554 22.6731 27.0323 21.6606 24.8912C20.6481 22.7502 21.0509 20.4003 22.5601 19.6419C24.0693 18.8835 25.1074 20.508 26.1178 22.6491Z"
				fill="#5F1307"
			/>
			<path
				d="M37.8822 22.6491C36.8697 24.7901 36.7376 25.6387 38.2468 26.397C39.7559 27.1554 41.3269 27.0323 42.3394 24.8912C43.3519 22.7502 42.9491 20.4003 41.4399 19.6419C39.9307 18.8835 38.8926 20.508 37.8822 22.6491Z"
				fill="#5F1307"
			/>
			<path
				d="M31.4405 19.4243C31.287 21.8006 30.9928 22.6029 29.3195 22.4887C27.6441 22.3743 26.3395 21.4621 26.493 19.0858C26.6465 16.7095 28.3965 13.8738 30.072 13.9881C31.7474 14.1024 31.594 17.048 31.4405 19.4243Z"
				fill="#5F1307"
			/>
			<path
				d="M33.0158 19.4221C33.1692 21.7984 33.4635 22.6029 35.1368 22.4887C36.8122 22.3743 38.1168 21.4621 37.9632 19.0858C37.8098 16.7095 36.0598 13.8738 34.3843 13.9881C32.7088 14.1024 32.8623 17.0458 33.0158 19.4221Z"
				fill="#5F1307"
			/>
		</>
	);
};

export default AzPetVetMapPinLight;
