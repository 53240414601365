const ClearChoiceMapPinDark = () => {
	return (
		<>
			<path
				d="M32 5.33333C21.68 5.33333 13.3334 13.68 13.3334 24C13.3334 38 32 58.6667 32 58.6667C32 58.6667 50.6667 38 50.6667 24C50.6667 13.68 42.32 5.33333 32 5.33333Z"
				fill="#0A2F54"
			/>
			<path
				d="M34.8288 21.5124L46.5 20.6564L36.5892 26.9667L40.9978 37.962L32.0228 30.3492L23.0722 38L27.4332 26.9853L17.5 20.7175L29.172 21.5242L31.9772 10L34.8288 21.5124Z"
				fill="white"
			/>
		</>
	);
};

export default ClearChoiceMapPinDark;
